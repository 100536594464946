<!--收银退货-->
<!--todo
1.关键字查询无效
2.机构名称查询无效
-->
<template>
    <div class="CashRefund">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="流水号">
                            <el-input placeholder="交易/退款流水号" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="收银员">
                            <el-input placeholder="退款收银员姓名/手机号" v-model="form.nameSearch" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select placeholder="请选择" v-model="form.deptCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="状态">
                            <el-select placeholder="请选择" v-model="form.settleStatus">
                                <el-option label="请选择" value="" />
                                <el-option label="待结算" value="0" />
                                <el-option label="已结算" value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.sale.cashRefund.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.sale.cashRefund.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                ref="table"
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="机构名称" width="100" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="退货流水号" width="100" prop="refundBar" v-if="showColumn('refundBar')" />
                <el-table-column label="交易流水号" width="100" prop="tradeBar" v-if="showColumn('tradeBar')" />
                <el-table-column label="数量" width="100" prop="goodsCount" v-if="showColumn('goodsCount')" />
                <el-table-column
                    label="退货类型"
                    width="100"
                    prop="refundType"
                    v-if="showColumn('refundType')"
                    key="refundType"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.refundType | refundType }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="扣款总额"
                    width="160"
                    prop="favourRefund"
                    v-if="showColumn('favourRefund')"
                    key="favourRefund"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.favourRefund | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="实退金额"
                    width="160"
                    prop="refundMoney"
                    v-if="showColumn('refundMoney')"
                    key="refundMoney"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.refundMoney | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="状态"
                    width="160"
                    prop="settleStatus"
                    v-if="showColumn('settleStatus')"
                    key="settleStatus"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.settleStatus | settleStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="退货收银员" width="120" prop="cashier" v-if="showColumn('cashier')" />
                <el-table-column label="创建时间" width="140" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    min-width="90"
                    header-align="center"
                    v-if="showColumn('operate')"
                    key="operate"
                    prop="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.sale.cashRefund.open')"
                            >查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import MoneyUtils from '../../../js/MoneyUtils';
import UrlUtils from '../../../js/UrlUtils';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import { DeptTypeEnum } from 'js/DeptCommon';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'CashRefund',
    mixins: [CheckTableShowColumn],
    components: { EfEndDate, EfStartDate, CheckTableShowColumnDialog },
    data() {
        return {
            form: {
                search: '',
                deptCode: '',
                nameSearch: '',
                goodsSearch: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                settleStatus: '',
                page: 1,
                limit: Util.Limit,
            },
            depts: [],
            current: {
                typeIdx: 3,
                deptCode: '',
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/refund/cash/page',
            },
        };
    },
    mounted() {
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            this.depts = rst;
            this.form.deptCode = this.depts[0].code;
            this.handleQuery();
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    filters: {
        settleStatus(settleStatus) {
            if (typeof settleStatus == 'undefined') {
                return '';
            }
            if (settleStatus == '0') {
                return '待结算';
            }
            if (settleStatus == '1') {
                return '已结算';
            }
        },
        refundType(refundType) {
            if (typeof refundType == 'undefined') {
                return '';
            }
            if (refundType == '1') {
                return '收银退货';
            }
            if (refundType == '2') {
                return 'c系统订单退货';
            }
        },
        money(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(MoneyUtils.moneyToYuan(money));
            }
            return '';
        },
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.sale.cashRefund.detail', ['销售管理', '收银退货', '收银退货详情'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            UrlUtils.Export(this, '收银退货', '/refund/cash/export', this.form);
        },
    },
};
</script>

<style scoped>
.CashRefund .el-form-item {
    margin-bottom: 0;
}
</style>
